import React, { useContext, useEffect, useState } from 'react';
import { Form, Button, Col, Row } from 'react-bootstrap';
import OtpInput from 'react-otp-input';
import { useNavigate, useParams } from 'react-router-dom';
import { Context } from '../common/AppCentralisedDataStore';
import { useDispatch } from 'react-redux';
import { submitOtp } from '../../redux/OtpSlice/OtpSlice';
import { toast } from 'react-toastify';
import { singIn } from '../../redux/authSlice/AuthSlice';

const OtpVerification = () => {
  const { setLoginEmail, loginEmail, loginPassword, setLoginPassword } =
    useContext(Context);

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});

  const [otp, setOtp] = useState('');

  const [otpLoading, setOtpLoading] = useState(false);
  const [sentOtpLoading, setSentOtpLoading] = useState(false);

  const handleOtpChange = (value) => {
    setOtp(value);
  };

  useEffect(() => {
    console.log(loginEmail, loginPassword);
    if (loginEmail === '' && loginPassword == '') {
      navigate('/login');
    }
  }, [loginEmail, loginPassword, navigate]);

  // const storeTime = () => {
  //   let expTime = new Date();
  //   expTime.setTime(expTime.getTime() + 10080 * 60 * 1000);
  //   localStorage.setItem('expTime', expTime);
  // };

  const validateForm = () => {
    const errors = {};
    if (!otp) {
      errors.otp = 'OTP is required.';
    } else if (!/^\d{6}$/.test(otp)) {
      errors.otp = 'OTP must be 6 digits.';
    }

    setErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const submitHandelOtp = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const values = {
        email: loginEmail,
        Otp: otp,
      };
      setOtpLoading(true);
      dispatch(submitOtp(values))
        .unwrap()
        .then((res) => {
          setOtpLoading(false);
          if (res?.token) {
            toast.success('Logged In Successfully', {
              position: 'top-right',
            });
            // storeUser(formValues);
            // storeTime();
            navigate('/dashboard');
          } else {
            toast.error('Please enter a valid OTP', {
              position: 'top-right',
            });
          }
        })
        .catch(() => {
          setOtpLoading(false);
          toast.error('Please enter a valid OTP', {
            position: 'top-right',
          });
        });

      console.log('Form Submitted', values);
    } else {
      setOtpLoading(false);
      console.log('Validation failed:', errors);
    }
  };

  const handelSubmitResendOtp = () => {
    console.log('1');
    setSentOtpLoading(true);

    const values = { email: loginEmail, password: loginPassword };

    dispatch(singIn(values))
      .unwrap()
      .then((res) => {
        setSentOtpLoading(false);
        if (
          res?.message ==
          'Two Factor Authentication required. Enter the OTP sent to your email.'
        ) {
          // console.log('res?.token', res?.token);
          toast.success(
            'Please enter the OTP that has been sent to your registered email address to proceed.',
            {
              position: 'top-right',
            }
          );
        } else {
          toast.error('Invalid username or password.', {
            position: 'top-right',
          });
        }
      })
      .catch(() => {
        setSentOtpLoading(false);
        toast.error('Invalid username or password.', {
          position: 'top-right',
        });
      });
  };
  return (
    <div className='vh-100 w-100 d-flex justify-content-center align-items-center auth_container'>
      <div className='w-100 p-4' style={{ maxWidth: '600px' }}>
        <div className='logo d-flex mx-auto justify-content-center mb-4'>
          <img src='/assets/img/logo/logo-1.png' width='350px' alt='Logo' />
        </div>
        <div className='text-center'>
          <h3 className='my-4' style={{ fontWeight: '650', color: '#494848' }}>
            Verify Your Email Address
          </h3>
          <Form>
            <Row className='my-4 justify-content-center'>
              <Col xs={12} sm={6}>
                <OtpInput
                  containerStyle={{ display: 'flex', justifyContent: 'center' }}
                  inputStyle={{
                    width: '3rem',
                    height: '3rem',
                    margin: '0.5rem',
                    fontSize: '1.5rem',
                    borderRadius: '4px',
                    border: '1px solid rgba(0,0,0,0.3)',
                  }}
                  value={otp}
                  onChange={handleOtpChange}
                  numInputs={6}
                  renderInput={(props) => <input {...props} />}
                />
              </Col>
              {errors && (
                <p style={{ color: 'red', textAlign: 'center' }}>
                  {errors?.otp}
                </p>
              )}
            </Row>
            <Row>
              {/* <p style={{ fontSize: '0.7rem' }}>
                Want to Chnage Your Email Address?{' '}
                <span
                  className='my-3'
                  style={{
                    textDecoration: 'underline',
                    fontWeight: '500',
                    cursor: 'pointer',
                    fontSize: '0.7rem',
                    colo: '#494848',
                  }}
                >
                  Change Here
                </span>
              </p> */}
            </Row>
            <Row className='justify-content-center my-3'>
              <Col xs={12} sm={6}>
                {otpLoading ? (
                  <Button
                    type='submit'
                    className='ne_btn_primary w-50'
                    style={{ borderRadius: '50px', fontWeight: 'bold' }}
                  >
                    Loading....
                  </Button>
                ) : (
                  <Button
                    onClick={(e) => submitHandelOtp(e)}
                    type='submit'
                    className='ne_btn_primary w-50'
                    style={{ borderRadius: '50px', fontWeight: 'bold' }}
                  >
                    Verify Email
                  </Button>
                )}
              </Col>
            </Row>
          </Form>

          <p
            onClick={() => handelSubmitResendOtp()}
            className='my-3'
            style={{
              textDecoration: 'underline',
              fontWeight: '495',
              cursor: 'pointer',
              fontSize: '0.8rem',
            }}
          >
            Resend code
          </p>
        </div>
      </div>
    </div>
  );
};

export default OtpVerification;
