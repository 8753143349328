export let baseUrl;
// export const serverUrl = "production";

export const serverUrl = "dev"; // for staging

if (serverUrl === "production") {
  baseUrl = "https://api.dentalbookingonline.com";
} else {
  baseUrl = "https://dentalsoftware.azurewebsites.net";
}

// if (process.env.REACT_APP_MY_API_KEY === "production") {
//   baseUrl = "https://api.dentalbookingonline.com";
// } else {
//   baseUrl = "https://dentalsoftware.azurewebsites.net";
// }
//
