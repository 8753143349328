import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { defaultLogin, defaultSignUp, getUser } from '../../services';

const initialState = {
  isError: false,
  message: '',

  isSignUp: false,
  isFetching: false,
};

export const singIn = createAsyncThunk(
  'auth/singIn',
  async (values, { rejectWithValue }) => {
    try {
      const data = await defaultLogin(values);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const singUp = createAsyncThunk(
  'auth/singUp',
  async (values, { rejectWithValue }) => {
    try {
      const data = await defaultSignUp(values);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearState: (state) => {
      state.isError = false;

      state.isFetching = false;
      state.message = '';
      state.isSignUp = false;

      return state;
    },
  },
  extraReducers: {
    [singIn.fulfilled]: (state, { payload }) => {
      state.isFetching = false;

      state.message = 'Success';
      return state;
    },
    [singIn.pending]: (state) => {
      state.isFetching = true;
      state.message = '';
      state.isError = false;
      return state;
    },
    [singIn.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isLogin = false;
      state.message = payload;
      return state;
    },
    [singUp.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSignUp = true;
      state.message = payload.message;
      return state;
    },
    [singUp.pending]: (state) => {
      state.isFetching = true;
      state.message = '';
      state.isError = false;
      return state;
    },
    [singUp.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isSignUp = false;
      state.message = payload;
      return state;
    },
  },
});

const { reducer } = authSlice;
export default reducer;
